/* eslint-disable */
(function(w) {
	var body = document.querySelector("body");
	var burger = document.querySelector(".burger");

	/**
	 * Handle Mobile Menu
	 */
	function handleMobileNav() {
		body.classList.toggle("show-menu");
	}

	/**
	 * Load
	 */
	function onLoad() {
		burger.addEventListener("click", handleMobileNav, false);
	}

	w.addEventListener("load", onLoad);
})(window);
